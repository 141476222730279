<template>
  <v-container
    class="container-full-size"
  >
    <v-card
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          v-if="hasPermission('plans_edit')"
          color="success"
          class="mr-0"
          @click="addPlan()"
        >
          {{ str['add'] }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card-text
          v-if="!plans.length && isLoading"
          class="text-center"
          style="padding: 40px 0;"
        >
          {{ str['processing'] }}
        </v-card-text>
        <v-card-text
          v-if="!plans.length && !isLoading"
          class="text-center"
          style="padding: 40px 0;"
        >
          {{ str['no_data'] }}
        </v-card-text>
        <v-list
          one-line
          style="min-height: 250px; padding: 0;"
        >
          <div
            v-for="plan in plans"
            :key="plan.id"
          >
            <v-list-item
              @click="openPlan(plan)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ plan.name }}
                  <span>
                    - <span v-bind:class="parent.getPlanStatus(plan, 'training_plans', plans).type + '--text'">
                      {{ parent.getPlanStatus(plan, 'training_plans', plans).label }}
                    </span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_dates">
                  {{ plan.start_date + ' / ' + plan.end_date }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user && user.configurations && user.configurations.training_plans && !user.configurations.training_plans.show_dates && plan.status !== 1">
                  {{ plan.start_date + ' / ' + plan.end_date }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="user && user.configurations && user.configurations.training_plans && !user.configurations.training_plans.show_dates && plan.status === 1">
                  {{ str['started_in'] }} {{ plan.start_date }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="client-list-item-actions">
                <v-icon
                  v-if="hasPermission('plans_edit')"
                  small
                  class="mr-0"
                  color="error"
                  @click.stop="deleteItem(plan)"
                >
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-col>
    </v-card>

    <v-dialog
      v-model="dialogNewPlan"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['plans_base'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px; padding: 10px 0;">
          <div
            v-for="basePlan in basePlans"
            :key="basePlan.id"
            @click="newPlan(basePlan)"
            class="box-select-item"
          >
            <span>
              {{ basePlan.name }}
            </span>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="dialogNewPlan = false"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="newPlan()"
            style="margin-right: 0;"
          >
            {{ str['add_pdf'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="plan"
      v-model="dialogPlan"
      persistent
      max-width="650px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['plan'] }}
          </span>
        </v-card-title>
        <div>
          <v-col
            cols="12"
            style="padding-bottom: 0;"
          >
            <v-text-field
              v-model="plan.name"
              name="input-7-1"
              :label="str['name']"
              class="purple-input"
            />
          </v-col>
          <v-col
            cols="12"
            style="padding-bottom: 0;"
          >
            <div
              style="display: flex;"
            >
              <div style="width: 100%; margin-right: 10px;">
                <v-menu
                  v-model="planStartDateDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="plan.start_date"
                      :label="str['start_date']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="plan.start_date"
                    style="margin: 0;"
                    @change="planStartDateDatepicker = false"
                    :locale="datepickerLanguage"
                  />
                </v-menu>
              </div>
              <div style="width: 100%; margin-left: 10px;">
                <v-menu
                  v-model="planEndDateDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="plan.end_date"
                      :label="str['end_date']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="plan.end_date"
                    style="margin: 0;"
                    @change="planEndDateDatepicker = false"
                    :locale="datepickerLanguage"
                  />
                </v-menu>
              </div>
            </div>
          </v-col>
          <div style="padding: 20px; display: flex; justify-content: center; align-items: center;">
            <v-btn
              color="blue"
              class="mr-0"
            >
              {{ str['add_pdf'] }}
            </v-btn>
            <input
              id="input-pdf-value"
              type="file"
              accept="application/pdf,application/vnd.ms-excel"
              @change="loadPlanPdf"
              style="opacity: 0; position: absolute; height: 45px; width: 160px;"
            />
          </div>
          <div style="padding: 0 0 20px;">
            <iframe
              v-if="plan.details"
              v-bind:src="plan.details"
              style="width: 100%; height: 50vh;"
            />
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogPlan = false"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveplan()"
            :disabled="!plan.start_date || !plan.end_date"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    name: 'client-plans',
    props: ['parent', 'client'],
    components: {
      ClientPhotos: () => import('@/views/dashboard/components/client/Photos'),
    },
    data() {
      const user = Utils.getUser()
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        hasPermission: Utils.hasPermission,
        user: user,
        isLoading: false,
        plans: [],
        basePlans: [],
        dialogNewPlan: false,
        planType: 1,
        dialogPlan: false,
        plan: null,
        planStartDateDatepicker: null,
        planEndDateDatepicker: null,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getPlans()
    },
    methods: {
      getPlans: function (callback) {
        const self = this
        this.isLoading = true
        Api.getTrainingPlans({
          client_id: this.client.dbId,
          fast: true,
          sort_order: this.user && this.user.configurations && this.user.configurations.training_plans && this.user.configurations.training_plans.show_sort ? true : false,
          fields: ['days_count', 'end_date', 'gender', 'id', 'level', 'name', 'place', 'proposal', 'start_date', 'status', 'type', 'base_id']
        }, function (response) {
          self.isLoading = false
          if (response.success) {
            self.plans = response.data
          }
          if (callback) {
            callback()
          }
        })
      },
      openPlan: function (item) {
        const self = this
        this.$isLoading(true)
        Api.getTrainingPlan({
          client_id: this.client.dbId,
          id: item.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.openPlanDialog(response.data[0])
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openPlanDialog: function (item, useBase) {
        const today = moment()
        this.plan = {
          id: !useBase && item ? item.id : null,
          name: item && item.name ? item.name : window.strings['plan'],
          details: item && item.details ? item.details : '',
          start_date: item && item.start_date? item.start_date : today.format('YYYY-MM-DD'),
          end_date: item && item.end_date ? item.end_date : today.add(7, 'days').format('YYYY-MM-DD'),
        }
        this.dialogPlan = true
      },
      addPlan: function () {
        const self = this
        this.$isLoading(true)
        this.getBasePlans(function (plans) {
          self.$isLoading(false)
          if (plans.length) {
            self.basePlans = plans
            self.dialogNewPlan = true
          } else {
            self.newPlan()
          }
        })
      },
      newPlan: function (item) {
        const self = this

        if (item && item.id) {
          this.$isLoading(true)
          Api.getTrainingPlan({
            id: item.id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              confirm(response.data[0])
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          confirm(item)
        }

        function confirm(planBase) {
          self.dialogNewPlan = false
          self.openPlanDialog(planBase, true)
        }
      },
      saveplan: function () {
        const self = this
        const data = this.getPlan()
        this.$isLoading(true)
        if (data.id) {
          Api.editTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogPlan = false
              self.getPlans()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          Api.newTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogPlan = false
              self.getPlans()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getPlan: function () {
        const plan = {
          client_id: this.client.dbId,
          name: this.plan.name,
          details: this.plan.details,
          start_date: this.plan.start_date,
          end_date: this.plan.end_date,
          type: this.planType,
        }
        if (this.plan && this.plan.id) {
          plan.id = this.plan.id
        }
        return plan
      },
      getBasePlans: function (callback) {
        const self = this
        let plans = []
        Api.getTrainingPlans({
          fast: true
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            plans = response.data
          }
          callback(plans)
        })
      },
      loadPlanPdf(e) {
        const self = this
        if (!e.target.files.length) {
          return false
        }
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          self.plan.details = e.target.result
        }
      },
      deleteItem (item) {
        const self = this
        this.$confirm(
          window.strings['want_delete_plan'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteTrainingPlan({
            id: item.id,
            client_id: self.client.dbId,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.getPlans()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
    },
  }
</script>
